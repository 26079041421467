@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.banner {
  display: flex;
  height: 32px;
  @include get-font-styles(copenhagen);
  align-items: center;
  text-indent: spacing(3);
  margin-bottom: spacing(1);

  &.omicron {
    background-color: get-color(omicron);
    color: get-color(primary);
  }

  &.rho {
    background-color: get-color(rho);
    color: get-color(pi);
  }

  &.nu {
    background-color: get-color(nu);
    color: get-color(xi);
  }

  &.sigma {
    background-color: get-color(sigma);
    color: get-color(omega);
  }

  &.tau {
    background-color: get-color(tau);
    color: get-color(gamma);
  }

  &.epsilon {
    background-color: get-color(epsilon);
    color: get-color(xi);
  }

  &.beta {
    background-color: get-color(beta);
    color: get-color(primary);
  }
}
