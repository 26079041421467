@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/spacing';

.modal {
  width: 100%;

  &:not(.uploadModal):not(.removeModal) {
    height: 100%;
  }

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.uploadModal {
  max-width: 684px;
  max-height: 100%;
}

.closeButton {
  padding-left: 0;
  padding-right: 0;
}

.row {
  display: flex;
  padding: 20px;

  + .row {
    padding-top: 0;
  }
}

.titleRow {
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }
}
