// @import '~styles/settings/variables';
// @import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/spacing';

.message {
  background-color: get-color(epsilon);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  padding: spacing(2, 1.5);
  margin: spacing(1, 0.5);

  &.ownMessage {
    background-color: get-color(chi);
    border-bottom-right-radius: 0;
    border-top-left-radius: 16px;
  }
}

.messageHeader {
  display: flex;
  align-items: center;
  gap: spacing(1);
  @include get-font-styles(copenhagen);
}

.messageDate {
  color: get-color(alpha);
}

.readStateButton {
  margin-left: auto;
}
