@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/spacing';

.root {
  padding: spacing(2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleRow {
  padding: spacing(1);
  margin-bottom: spacing(2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    padding: 0;
  }
}

.warning {
  display: flex;
  gap: spacing(1);
  background-color: get-color(gamma);
  color: get-color(beta);
  align-items: center;
  padding: spacing(0.5);
  border-radius: 4px;
  margin-bottom: spacing(3);
}

.textArea {
  background-color: get-color(beta);
  border-width: 1px;
  border-color: get-color(primary);
  height: 100px;
}

.spinner {
  margin: spacing(4) auto;
  // Size of spinner
  top: 25px;
  left: 25px;
}

.error {
  color: get-color(gamma);
}
