@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/spacing';

.rows {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.row {
  display: flex;
  padding: 20px;

  + .row {
    padding-top: 0;
  }
}

.endRow {
  margin-left: auto;
  justify-content: flex-end;

  button {
    margin-left: spacing(2);
    padding-left: spacing(2);
    padding-right: spacing(2);
  }

  .addRowButton {
    margin-left: 0;
    margin-right: auto;
    padding-left: 0;
    color: get-color(alpha);
    font-weight: 400;
  }
}

.dropzone {
  border: 2px dashed get-color(epsilon);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 100%;
  min-height: 164px;
  overflow: auto;
  margin: 0 20px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  padding: spacing(1);
  outline: 0;

  .uploading & {
    pointer-events: none;
  }

  .dropzoneContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dropzoneCloud {
    color: get-color(rho);
    font-size: 40px;

    .uploading & {
      color: get-color(epsilon);
    }
  }

  .dropzoneText,
  .dropzoneOr {
    margin: spacing(0.5, 0);

    .uploading & {
      color: get-color(koppa);
    }
  }

  .dropzoneText {
    @include get-font-styles(copenhagen);
  }

  .dropzoneOr {
    @include get-font-styles(stockholm);
    color: get-color(eta);
  }
}

.fileItems {
  display: flex;
  flex-direction: column;

  .fileItem {
    display: flex;
    flex-direction: row;
    margin-bottom: spacing(1);
    align-items: flex-start;
  }

  .fileIcon {
    align-items: center;
    border-radius: 4px;
    background-color: get-color(epsilon);
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: spacing(2);
    width: 40px;

    > i {
      color: get-color(eta);
    }
  }

  .fileInfo {
    display: flex;
    justify-content: space-between;
    flex: 1;

    input {
      margin: 0;
    }

    button {
      margin-right: 6px;
      line-height: 0;
      
      &:not(.showSuffix) {
        display: none;
      }
    }
  }

  .removeFileButton {
    margin: spacing(0, -1, 0, 1);
    padding: spacing(1);
  }
}

.inputs {
  max-height: 60vh;
  overflow-y: auto;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  >span {
    margin-left: spacing(3);
    @include get-font-styles(copenhagen);
    color: get-color(gamma);
  }
}
