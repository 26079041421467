@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.block {
  background-color: get-color(lambda);
  border: 1px solid get-color(epsilon);
  border-radius: spacing(0.5);
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: spacing(2, 0);
  margin-bottom: spacing(2);
}

.whiteBackground {
  background-color: get-color(beta);
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search {
  width: 100%;
  max-width: 360px;
  padding: spacing(0, 2, 2);

  input {
    margin-bottom: 0;
  }
}

.sortingContainer {
  width: 100%;
  max-width: 360px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  z-index: 2;
  padding: spacing(0, 2, 2);
}

.sortingContainer p {
  padding: spacing(0, 2);
}

.reactSelectContainer {
  flex: 1;

  :global {
    .react-select__control {
      background-color: get-color(epsilon);
      border: 0;
    }

    .react-select__dropdown-indicator {
      color: get-color(primary);
    }

    .react-select__option--is-focused {
      background-color: get-color(phi);
      color: get-color(primary);
    }

    .react-select__dropdown-indicator:hover {
      color: unset;
    }
  }
}

.webshopModal {
  overflow-x: hidden;
}

.price {
  &__red {
    color: get-color(fi);
  }

  &__original {
    text-decoration-line: line-through;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  padding: spacing(3);
}

.modalButton {
  margin: spacing(0, 3, 4);
  margin-bottom: 100%;
}

.showImageWrapper {
  display: flex;
  align-items: center;
}

.showImageButton {
  color: get-color(alpha);
  padding: 0 0 0 spacing(0.5);
}
