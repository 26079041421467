@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.stockBalance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: spacing(0.5);
  @include get-font-styles(reykjavik);
  font-weight: 400;
  white-space: normal;
  gap: spacing(1);
}

.textRows {
  display: flex;
  flex-direction: column;
}

.dot {
  flex: none;
  height: 10px;
}

.green {
  fill: get-color(omega);
}

.yellow {
  fill: get-color(status-info-dark);
}

.red {
  fill: get-color(gamma);
}

.infoIcon {
  height: 18px;
  fill: get-color(eta);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
}

.deliveries {
  margin: spacing(1);
  display: flex;
  flex-direction: column;
  @include get-font-styles(samarkand);
}
